<template>
	<div>
		<div v-if="edit">
			<div @click="dialog = true" class="pa-1 rounded-lg cell2 elevation-2 d-flex cursor-pointer" style="width: 32px; height: 32px; justify-content: center; align-items: center">
				<v-icon size="18">{{ new_val }}</v-icon>
			</div>
		</div>
		<div v-else>
			<v-icon size="18">{{ new_val }}</v-icon>
		</div>

		<Dialog v-model="dialog" :title="$t('single_trad.Dialog.icon_field')" @close="dialog = false">
			<template v-slot:content>
				<div class="pa-3">
					<TextField v-model="search" clearable :label="$t('global.action.search')" appendIcon="mdi-magnify" />
				</div>
				<div style="height: 296px">
					<v-virtual-scroll
							v-if="dialog"
							:items="icons"
							height="296.1"
							item-height="49.35"
					>
						<template v-slot:default="{ item }">
							<v-row class="mx-0">
								<template v-for="icon in item">
									<v-hover v-slot:default="{ hover }">
										<v-col class="cursor-pointer" :class="hover || new_val === 'mdi-' + icon.replaceAll('_', '-') ? 'cell2':''" @click="new_val = 'mdi-' + icon.replaceAll('_', '-'); dialog = false">
											<v-icon :color="new_val === 'mdi-' + icon.replaceAll('_', '-') ? 'secondary':''">mdi-{{ icon.replaceAll('_', '-') }}</v-icon>
										</v-col>
									</v-hover>
								</template>
							</v-row>
						</template>
					</v-virtual-scroll>
				</div>
			</template>
		</Dialog>
	</div>
</template>

<script>
	export default {
		name: "IconField",
		props: {value: {}, edit: {type: Boolean}},
		components: {
			TextField: () => import('@/components/fields/TextField.vue'),
			Dialog: () => import('@/components/ui/Dialog.vue')
		},
		data(){
			return {
				icons: [],
				dialog: false,
				search: ''
			}
		},
		computed: {
			new_val: {
				get(){
					return this.value
				},
				set(val){
					this.$emit('input', val)
				}
			}
		},
		created(){
			fetch("/icons.json")
				.then((res) => res.json())
				.then((data) => {
					this.icons = data
				})
		}
	}
</script>

<style scoped>

</style>